import KnowledgeBaseNavigation from 'javascripts/components/knowledge_base/navigation';
import OkDeskTabs from 'javascripts/vendors/okdesk-tabs';
import ModalDialog from 'javascripts/vendors/modal_dialog';
import ScrollableContent from 'javascripts/vendors/scrollable_content';
import FlashBlock from 'javascripts/main/flash_block';

$(document).on('page:change', () => {
  KnowledgeBaseNavigation.init();
  FlashBlock.init();
  ModalDialog.init();
  ScrollableContent.init();
});

$(document).on('ready page:load', () => {
  OkDeskTabs.init();
});
